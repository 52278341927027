import { store } from '../index'

export default {
  namespaced: true,
  state: {
    isAuthenticated: false,
    account: {},
    isLoggingOut: false,
    profileUpdate: false,
    passwordUpdate: false,
  },
  mutations: {
    updateIsAuthenticated(state, value) {
      state.isAuthenticated = value;
    },
    updateAccount(state, value) {
      state.account = value;
    },
    updateIsLoggingOut(state, value) {
      state.isLoggingOut = value;
    },
    updateProfileUpdate(state, value) {
      state.profileUpdate = value;
    },
    updatePasswordUpdate(state, value) {
      state.passwordUpdate = value;
    },
    resetEmployeeAndClient() {
      store.state.employeeId = null;
      store.state.currentClientId = null;
    },
  },
  getters: {
    getIsAuthenticated(state) {
        return state.isAuthenticated;
    },
    getAccount(state) {
        return state.account;
    },
    getIsLoggingOut(state) {
      return state.isLoggingOut;
    },
    getProfileUpdate(state) {
      return state.profileUpdate;
    },
    getPasswordUpdate(state) {
      return state.passwordUpdate;
    },
  },
  actions: {
    async login({ commit }, account) {
      commit('updateAccount', account);
      commit('updateIsAuthenticated', true);
    },
    async loginWithoutAccount({ commit }) {
      commit('updateIsAuthenticated', true);
    },
    logout({ commit }) {
      commit('updateAccount', {});
      commit('resetEmployeeAndClient', {});
      commit('updateIsLoggingOut', true);
      commit('updateIsAuthenticated', false);
    },
    setProfileUpdate({ commit }, value) {
      commit('updateProfileUpdate', value)
    },
    setPasswordUpdate({ commit }, value) {
      commit('updatePasswordUpdate', value)
    },
  },
};


