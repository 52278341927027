<script>
import SvgIcon from './SvgIcon';
import { mapMutations, mapGetters, mapActions  } from 'vuex';
export default {
  name: 'main-nav',
  data() {
    return {
      accountSubmenuOpen: false,
    }
  },
  components: {
    'svg-icon': SvgIcon,
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/getIsAuthenticated',
      isLoggingOut: 'auth/getIsLoggingOut',
      currentClientName: 'getCurrentClientName',
      currentClientId: 'getCurrentClientId',
      clients: 'getClients',
      employeeDetails: 'employee/getDetails',
    }),
    otherClients: function() {
      var other = []
      this.clients.forEach(function(client) {
          if(client.client_id != this.currentClientId) {
              other.push(client)
          }
      }.bind(this));
      return other
    },
  }, 
  methods: {
    closeMenu() {
      this.$refs.mainNavigationContainer.classList.remove('open')
    },
    showHealthCard () {
      this.healthCardOpen = !this.healthCardOpen;
      this.$emitter.emit('SHOW_HEALTH_CARD', true);
    },
    signOut() {
      this.$msalInstance.logout({
        account: this.account,
      });
      this.storeLogout()
      this.updateIsAuthenticated(false)
      this.updateAccount(null)
    },
    async switchCompany(clientId)  {
      this.updateIsLoggingOut(true)
      await this.resetEmployeeData()
      await this.switchCurrentClient(clientId)
      this.updateIsLoggingOut(false)
    },
    async editProfile() {
      await this.setProfileUpdate(true)
      this.$router.push({
        name: 'login'
      })
    },
    async changePassword() {
      await this.setPasswordUpdate(true)
      this.$router.push({
        name: 'login'
      })
    },
    ...mapMutations({
      updateIsAuthenticated: 'auth/updateIsAuthenticated',
      updateAccount: 'auth/updateAccount',
      updateIsLoggingOut: 'auth/updateIsLoggingOut',
      resetEmployeeData: 'employee/RESET_DATA',
    }),
    ...mapActions({
      storeLogout: 'auth/logout',
      switchCurrentClient: 'employee/switchCurrentClient',
      setProfileUpdate: 'auth/setProfileUpdate',
      setPasswordUpdate: 'auth/setPasswordUpdate',
    })
  },
  mounted() {
    this.$emitter.on('TRIGGER_MOBILE_MENU', function() { this.$refs.mainNavigationContainer.classList.toggle('open') }.bind(this))
  },
  unmounted() {
    this.$emitter.all.delete('TRIGGER_MOBILE_MENU')
  }
}
</script>

<template v-if="!store.state.loading">
  <div class="main-navigation-container fixed" ref="mainNavigationContainer">
    <div class="main-navigation-close-underlay hidden" @click="closeMenu()"></div>
    <div class="main-navigation bg-black font-medium overflow-auto" ref="mainNavigation" v-if="(isAuthenticated || isLoggingOut)">
      <router-link to="/" class="logo-link"><svg-icon icon="gms-connect-logo-transparent"></svg-icon></router-link>
      <template v-if="Object.keys(this.employeeDetails).length">
        <ul class="list-none my-2 pt-7 pb-3">
          <span class="text-xs uppercase mb-2 block">Important Links</span>
          <li class="account-settings-link" v-bind:class="{ open: accountSubmenuOpen }">
            <a @click="accountSubmenuOpen = !accountSubmenuOpen">Account Settings</a>
            <div class="account-settings" v-if="accountSubmenuOpen">
              <div class="px-7 pb-2 pt-1 text-white" v-if="clients.length > 1">
                <div class="whitespace-nowrap font-bold mb-1 leading-7 client-name">{{ employeeDetails.full_name }}</div>
                <div class="whitespace-nowrap text-sm font-normal">{{ currentClientName }}</div>
              </div>
              <router-link to="/account" class="group flex items-center px-4 py-2 text-sm whitespace-nowrap" title="Account" @click="closeMenu()">
                View Account
              </router-link>
              <div class="mb-3" v-if="clients.length > 1" :key="currentClientId">
                <template v-for="client in otherClients" :key="client.client_id">
                  <span class="text-xs uppercase mt-2 mb-1 block">Switch Company</span>
                  <a @click="switchCompany(client.client_id)" class="group flex items-center px-4 py-2 text-sm whitespace-nowrap">
                    {{ client.client_name }}
                  </a>
                </template>
              </div>
              <span class="text-xs uppercase mt-2 mb-1 block">Security</span>
              <a @click="editProfile()" class="group flex items-center px-4 py-2 text-sm whitespace-nowrap">
                Login Preferences
              </a>
              <a @click="changePassword()" class="group flex items-center px-4 py-2 text-sm whitespace-nowrap">
                Change Password
              </a>
              <a href="https://www.groupmgmt.com/gms-mobile-application/" class="group flex items-center px-4 py-2 text-sm whitespace-nowrap">
                Terms and Conditions
              </a>
              <a @click="signOut()" class="group flex items-center px-4 py-2 text-sm">
                Sign Out
              </a>
            </div>
          </li>
          <li>
            <a @click="showHealthCard" title="Health Insurance Card" v-if="employeeDetails.healthcare !== false">Insurance Card</a>
          </li>
        </ul>
      </template>
      <ul class="list-none my-2 py-6 lg:py-7">
        <span class="text-xs uppercase mb-2 block">Menu</span>
        <li v-bind:class="{ active: ($route.meta.title == 'Dashboard') }">
          <router-link to="/" @click="closeMenu()"><svg-icon icon="dashboard"></svg-icon>Dashboard</router-link>
        </li>
        <li v-bind:class="{ active: ($route.meta.title == 'My Pay') }">
          <router-link to="/my-pay" @click="closeMenu()"><svg-icon icon="my_pay"></svg-icon>My Pay</router-link>
        </li>
        <li v-bind:class="{ active: ($route.meta.title == 'My Time') }">
          <router-link to="/my-time" @click="closeMenu()"><svg-icon icon="my_time"></svg-icon>My Time</router-link>
        </li>
        <li v-bind:class="{ active: ($route.meta.title == 'Health & Wellness') }">
          <router-link to="/health-and-wellness" @click="closeMenu()"><svg-icon icon="health"></svg-icon>Health & Wellness</router-link>
        </li>
        <li v-bind:class="{ active: ($route.meta.title == 'Dental & Vision') }">
          <router-link to="/dental-and-vision" @click="closeMenu()"><svg-icon icon="dental"></svg-icon>Dental & Vision</router-link>
        </li>
        <li v-bind:class="{ active: ($route.meta.title == 'Financial Wellness') }">
          <router-link to="/financial-wellness" @click="closeMenu()"><svg-icon icon="financial"></svg-icon>Financial Wellness</router-link>
        </li>
        <li v-bind:class="{ active: ($route.meta.title == 'Worker\'s Compensation') }" @click="test">
          <router-link to="/workers-compensation" @click="closeMenu()"><svg-icon icon="workers_compensation"></svg-icon>Worker's Compensation</router-link>
        </li>
        <li v-bind:class="{ active: ($route.meta.title == 'Worksite Safety') }">
          <router-link to="/worksite-safety" @click="closeMenu()"><svg-icon icon="worksite_safety"></svg-icon>Worksite Safety</router-link>
        </li>
        <li v-bind:class="{ active: ($route.meta.title == 'Additional Benefits') }">
          <router-link to="/additional-benefits" @click="closeMenu()"><svg-icon icon="benefits"></svg-icon>Additional Benefits</router-link>
        </li>
      </ul>
    </div>
    <a class="close-mobile-menu absolute text-center hidden" @click="closeMenu()"><svg-icon icon="close-large"/></a>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.main-navigation-container {
  width: 270px;
  height: 100vh;
  z-index: 25;
}
.main-navigation-close-underlay {
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 1;
}
.main-navigation {
  padding: 16px 0 16px;
  height: 100vh;
  width: 270px;
  position: relative;
  z-index: 2;
  .logo-link {
    padding: 0 30px;
  }
  ul {
    border-top: 1px solid #2E2E33;
    .svg-container {
      margin-right: 18px;
      min-width: 20px;
      text-align: center;
    }
    svg {
      @apply inline-block;
    }
    a {
      @apply text-white block;
      padding: 11px 30px;
      cursor: pointer;
    }
    span {
      color: #9EA0A5;
      padding: 0 30px;
    }
    li {
      @apply text-sm transition-all;
      &.active, &:hover {
        background: rgba(0,156,222,0.20);
        box-shadow: inset 3px 0 0 0 #009CDE;
      }
      &.account-settings-link {
        position: relative;
        &:after {
          content: '';
          position: absolute;
          display: block;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 7px 4px 0 4px;
          border-color: #ffffff transparent transparent transparent;
          top: 18px;
          right: 28px;
        }
        &:hover {
          background: none;
          box-shadow: none;
        }
        .account-settings {
          display: none;
          a {
            color: #2F77C8;
          }
        }
        &.open {
          .account-settings {
            display: block;
          }
          &:after {
            border-width: 0 4px 7px 4px;
            border-color: transparent transparent #ffffff transparent;
          }
        }
      }
      .client-name {
        font-size: 16px;
      }
    }
  }
}

.close-mobile-menu {
  left: 270px;
  top: 0;
  background: #fff;
  width: 60px;
  height: 59px;
  background: #FFFFFF;
  box-shadow: 1px 0 0 0 rgba(0,0,0,0.06);
  padding-top: 19px;
  z-index: 2;
}
</style>