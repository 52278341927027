import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import AuthStore from './modules/AuthStore';
import Employee from './modules/Employee';
import Content from './modules/Content';

export const store = createStore({
  modules: {
    auth: AuthStore,
    employee: Employee,
    content: Content,
  },
  state: {
    lastRoute: '',
    employeeId: '',
    currentClientId: '',
    currentClientIdReset: '',
    currentClientName: '',
    clients: [],
    loading: true,
    contentLoading: true,
  },
  mutations: {

  },
  actions: {

  },
  getters: {
    getCurrentClientId(state) {
      return state.currentClientId
    },
    getCurrentEmployeeId(state) {
      return state.employeeId
    },
    getCurrentClientIdReset(state) {
      return state.currentClientIdReset
    },
    getCurrentClientName(state) {
      return state.currentClientName
    },
    getClients(state) {
      return state.clients
    }
  },
  plugins: [createPersistedState({
    paths: ['clients', 'employeeId', 'currentClientId', 'currentClientName', 'lastRoute']
  })],
});
