// import { createApp } from 'vue'
// import App from './App.vue'

// createApp(App).mount('#app')
//window.localStorage.clear();
import { createApp } from 'vue'
import App from './App.vue'
import router from './routes'
import { store } from './store/index'
import mitt from 'mitt';

import Default from './layouts/Default.vue'
import Login from './layouts/Login.vue'

import './style.scss';
import { getMsalInstance } from './msal';

const app = createApp(App)

app.config.globalProperties.$routeKey = null

const emitter = mitt();
app.config.globalProperties.$emitter = emitter;

app.use(router)
  .use(store)
  .component('login-layout', Login)
  .component('default-layout', Default)

const vm = app.mount('#app')

app.config.globalProperties.$vm = vm

app.config.devtools = true;
app.config.globalProperties.$filters = {
  currencyUSD(value) {
    value = parseFloat(value);
    if (typeof value !== "number") {
        return value;
    } else {
      var formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2
        });
        return formatter.format(value);
    }
  },
  dateToMDY(date) {
    if(!date) {
      return '';
    }
    date = new Date(date);
    const d = date.getUTCDate(),
      m = date.getMonth() + 1,
      y = date.getFullYear();
    return '' + (m<=9 ? '0' + m : m) + '/' + (d <= 9 ? '0' + d : d) + '/' + y
  },
  dateToMD(date) {
    if(!date) {
      return '';
    }
    date = new Date(date);
    const strArray=['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      d = date.getUTCDate(),
      m = strArray[date.getMonth()];
    return m + ' ' + (d <= 9 ? '0' + d : d)
  }
}

app.config.globalProperties.$msalInstance = getMsalInstance();
