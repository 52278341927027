<script>
import SvgIcon from './SvgIcon';
import HealthInsuranceCard from '../views/modals/HealthInsuranceCard';
import { mapMutations, mapGetters, mapActions } from 'vuex';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { store } from '../store/index'
export default {
  name: 'app-header',
  data() {
    return {
      isLoading: true,
      healthCardOpen: false,
      scopes: [process.env.VUE_APP_B2C_SCOPE_READ + 'user_impersonation'],
      store: store
    };
  },
  components: {
    'svg-icon': SvgIcon,
    HealthInsuranceCard,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
  },
  methods: {
    showHealthCard () {
      this.healthCardOpen = !this.healthCardOpen;
      this.$emitter.emit('SHOW_HEALTH_CARD', true);
    },
    signOut() {
      this.$msalInstance.logout({
        account: this.account,
      });
      this.storeLogout()
      this.updateIsAuthenticated(false)
      this.updateAccount(null)
    },
    async switchCompany(clientId)  {
      this.updateIsLoggingOut(true)
      await this.resetEmployeeData()
      await this.switchCurrentClient(clientId)
      this.updateIsLoggingOut(false)
    },
    async editProfile() {
      await this.setProfileUpdate(true)
      this.$router.push({
        name: 'login'
      })
    },
    async changePassword() {
      await this.setPasswordUpdate(true)
      this.$router.push({
        name: 'login'
      })
    },
    openMobileMenu() {
      this.$emitter.emit('TRIGGER_MOBILE_MENU')
    },
    ...mapMutations({
      updateIsAuthenticated: 'auth/updateIsAuthenticated',
      updateAccount: 'auth/updateAccount',
      updateIsLoggingOut: 'auth/updateIsLoggingOut',
      resetEmployeeData: 'employee/RESET_DATA',
    }),
    ...mapActions({
      storeLogout: 'auth/logout',
      fetchDetails: 'employee/fetchDetails',
      switchCurrentClient: 'employee/switchCurrentClient',
      setProfileUpdate: 'auth/setProfileUpdate',
      setPasswordUpdate: 'auth/setPasswordUpdate',
    })
  },
  computed: {
    pageTitle: function() {
      return this.$route.meta.title;
    },
    otherClients: function() {
      var other = []
      this.clients.forEach(function(client) {
          if(client.client_id != this.currentClientId) {
              other.push(client)
          }
      }.bind(this));
      return other
    },
    ...mapGetters({
      isAuthenticated: 'auth/getIsAuthenticated',
      isLoggingOut: 'auth/getIsLoggingOut',
      clients: 'getClients',
      account: 'auth/account',
      employeeDetails: 'employee/getDetails',
      currentClientName: 'getCurrentClientName',
      currentClientId: 'getCurrentClientId',
    })
  },
  async mounted() {
    if ( this.currentClientId && Object.keys(this.employeeDetails).length === 0 ) {
      store.state.loading = true;
      await this.fetchDetails();
      store.state.loading = false;
    } else {
      store.state.loading = false;
    }
  }
}
</script>

<template>
  <header class="z-20" v-if="(isAuthenticated && !store.state.loading && !isLoggingOut)">
    <div class="lg:flex w-full">
      <a @click="openMobileMenu()" class="absolute lg:hidden mobile-menu-trigger text-center"><svg-icon icon="mobile-menu-trigger"/></a>
      <h1 class="flex-1 font-medium text-lg text-center lg:text-left">{{ pageTitle }}</h1>
      <ul class="list-none flex-1 justify-end flex header-link-icons">
        <Menu as="li" class="relative inline-block text-left dropdown-icon">
          <div>
            <MenuButton class="">
              <span class="sr-only">Open account</span>
              <svg-icon icon="user"></svg-icon>
            </MenuButton>
          </div>

          <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
            <MenuItems class="origin-top-right absolute mt-2 pb-1 z-10 -right-14 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div class="px-4 pb-2 pt-5" v-if="clients.length > 1">
                <div class="whitespace-nowrap font-bold mb-1 leading-7">{{ employeeDetails.full_name }}</div>
                <div class="whitespace-nowrap text-sm font-normal">{{ currentClientName }}</div>
              </div>
              <div class="py-1">
                <MenuItem>
                  <router-link to="/account" class="group flex items-center px-4 py-2 text-sm whitespace-nowrap" title="Account">
                    View Account
                  </router-link>
                </MenuItem>
                <div class="mb-2" v-if="clients.length > 1" :key="currentClientId">
                  <hr class="mx-4 mt-1 mb-3">
                  <template v-for="client in otherClients" :key="client.client_id">
                    <div class="uppercase text-xs gray-text px-4 py-1">Switch Company</div>
                    <MenuItem v-if="clients.length > 1">
                      <a @click="switchCompany(client.client_id)" class="group flex items-center px-4 py-2 text-sm whitespace-nowrap">
                        {{ client.client_name }}
                      </a>
                    </MenuItem>
                  </template>
                </div>
                <hr class="mx-4 mt-1 mb-3">
                <div class="uppercase text-xs gray-text px-4 py-1">Security</div>
                <MenuItem>
                  <a @click="editProfile()" class="group flex items-center px-4 py-2 text-sm whitespace-nowrap">
                    Login Preferences
                  </a>
                </MenuItem>
                <MenuItem>
                  <a @click="changePassword()" class="group flex items-center px-4 py-2 text-sm whitespace-nowrap">
                    Change Password
                  </a>
                </MenuItem>
                <hr class="mx-4 my-2">
                <MenuItem>
                  <a @click="signOut()" class="group flex items-center px-4 py-2 text-sm">
                    Sign Out
                  </a>
                </MenuItem>
              </div>
            </MenuItems>
          </transition>
        </Menu>

        <!-- <li><router-link to="/account"><svg-icon icon="user"></svg-icon></router-link></li> -->
        <li><router-link to="/documents"><svg-icon icon="document" title="Documents"></svg-icon></router-link></li>
        <li v-if="Object.keys(employeeDetails).length !== 0 && employeeDetails.healthcare !== false"><a @click="showHealthCard" title="Health Insurance Card"><svg-icon icon="card"></svg-icon></a></li>
        <!-- <li><a href=""><svg-icon icon="search"></svg-icon></a></li> -->
      </ul>
    </div>
  </header>
  <HealthInsuranceCard v-if="isAuthenticated" />
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
button:focus {
  @apply focus:outline-none;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
  .svg-container {
    @apply inline-block align-middle;
  }
}
.dropdown-icon {
  a {
    color: #1665D8;
  }
}
a {
  color: #42b983;
}
.mobile-menu-trigger {
  width: 60px;
  height: 60px;
  top: 0;
  left: 0;
  padding-top: 19px;
}
</style>