import axios from 'axios';
const httpClient = axios.create({
    baseURL: process.env.VUE_APP_BASE_API_URL,
    timeout: 80000,
    headers: {
        'Content-Type': 'multipart/form-data',
        'Ocp-Apim-Subscription-Key': process.env.VUE_APP_APIM_SUBSCRIPTION_KEY,
    }
});

export default httpClient;