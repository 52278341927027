import {
    getClients,
    getDetails,
    getCompensationUpdated,
    getPayrollVoucher,
    getHours,
    getPTO,
    getHealthcare,
    getClaims,
    getDental,
    getVision,
    getRetirement,
    getLifeInsurance,
    getElectionsAndDocuments,
    getVerification,
 } from "../../api/employee.api"

 import { store } from '../index'

const getDefaultState = () => {
    return {
        employee: {
            details: {},
            compensation: {},
            payrollVouchers: [],
            hours: {},
            pto: {},
            healthcare: {},
            claims: {},
            dental: {},
            vision: {},
            retirement: {},
            lifeInsurance: {},
            electionsAndDocuments: {},
            verification: {},
        }
    }
}// initial state
const state = getDefaultState()

const getters = {
    getClients(state) {
        return state.employee.clients
    },
    getDetails(state) {
        return state.employee.details;
    },
    getCompensationUpdated(state) {
        return state.employee.compensation;
    },
    getPayrollVouchers(state) {
        return state.employee.payrollVouchers;
    },
    getHours(state) {
        return state.employee.hours;
    },
    getPTO(state) {
        return state.employee.pto;
    },
    getHealthcare(state) {
        return state.employee.healthcare;
    },
    getClaims(state) {
        return state.employee.claims;
    },
    getDental(state) {
        return state.employee.dental;
    },
    getVision(state) {
        return state.employee.vision;
    },
    getRetirement(state) {
        return state.employee.retirement;
    },
    getLifeInsurance(state) {
        return state.employee.lifeInsurance;
    },
    getElectionsAndDocuments(state) {
        return state.employee.electionsAndDocuments;
    },
    getVerification(state) {
        return state.employee.verification;
    },
}

const actions = {
    async fetchClients({ commit }, data) {
        try {
            const response = await getClients(data.epusername, data.corporateEPusername);
            if (response) {
                commit('SET_CLIENTS', response.data);
                return true
            }
        } catch (error) {
            console.error("Error response:");
            console.error(error.response.data);    // ***
            console.error(error.response.status);  // ***
            console.error(error.response.headers); // ***
            console.log(error.response.data.message);
            return false
        }
    },
    async fetchDetails({ commit }) {
        try {
            const response = await getDetails(store.state.employeeId, store.state.currentClientId);
            commit('SET_DETAILS', response.data);
        } catch (error) {
            // handle the error here
        }
    },
    async fetchCompensation({ commit }) {
        try {
            const response = await getCompensationUpdated(store.state.employeeId, store.state.currentClientId);
            commit('SET_COMPENSATION', response.data);
        } catch (error) {
            // handle the error here
        }
    },
    async fetchPayrollVoucher({ commit }, id) {
        try {
            const response = await getPayrollVoucher(store.state.employeeId, store.state.currentClientId, id);
            commit('ADD_PAYROLL_VOUCHER', response.data);
        } catch (error) {
            // handle the error here
        }
    },
    async fetchHours({ commit }) {
        try {
            const response = await getHours(store.state.employeeId, store.state.currentClientId);
            commit('SET_HOURS', response.data);
        } catch (error) {
            // handle the error here
        }
    },
    async fetchPTO({ commit }) {
        try {
            const response = await getPTO(store.state.employeeId, store.state.currentClientId);
            commit('SET_PTO', response.data);
        } catch (error) {
            // handle the error here
        }
    },
    async fetchHealthcare({ commit }) {
        try {
            const response = await getHealthcare(store.state.employeeId, store.state.currentClientId);
            commit('SET_HEALTHCARE', response.data);
        } catch (error) {
            // handle the error here
        }
    },
    async fetchClaims({ commit }) {
        try {
            const response = await getClaims(store.state.employeeId, store.state.currentClientId);
            commit('SET_CLAIMS', response.data);
        } catch (error) {
            // handle the error here
        }
    },
    async fetchDental({ commit }) {
        try {
            const response = await getDental(store.state.employeeId, store.state.currentClientId);
            commit('SET_DENTAL', response.data);
        } catch (error) {
            // handle the error here
        }
    },
    async fetchVision({ commit }) {
        try {
            const response = await getVision(store.state.employeeId, store.state.currentClientId);
            commit('SET_VISION', response.data);
        } catch (error) {
            // handle the error here
        }
    },
    async fetchRetirement({ commit }) {
        try {
            const response = await getRetirement(store.state.employeeId, store.state.currentClientId);
            commit('SET_RETIREMENT', response.data);
        } catch (error) {
            // handle the error here
        }
    },
    async fetchLifeInsurance({ commit }) {
        try {
            const response = await getLifeInsurance(store.state.employeeId, store.state.currentClientId);
            commit('SET_LIFE_INSURANCE', response.data);
        } catch (error) {
            // handle the error here
        }
    },
    async fetchElectionsAndDocuments({ commit }) {
        try {
            const response = await getElectionsAndDocuments(store.state.employeeId, store.state.currentClientId);
            commit('SET_ELECTIONS_AND_DOCUMENTS', response.data);
        } catch (error) {
            // handle the error here
        }
    },
    async fetchVerification({ commit }) {
        try {
            const response = await getVerification(store.state.employeeId, store.state.currentClientId);
            commit('SET_VERIFICATION', response.data);
        } catch (error) {
            // handle the error here
        }
    },
    switchCurrentClient({ commit }, clientId) {
        var currentClient;
        store.state.clients.forEach(function(client) {
            if(clientId == client.client_id) {
                currentClient = client;
            }
        });
        // console.log('SET CURRENT CLIENT')
        // console.log(currentClient)
        commit('SET_CURRENT_CLIENT', currentClient)
    },
}


const mutations = {
    SET_CLIENTS(state, data) {
        store.state.clients = data.clients;
        store.state.employeeId = data.employee_id;
        store.state.currentClientId = data.clients[0].client_id;
        // store.state.employeeId = 1;
        // store.state.currentClientId = '00001';
        store.state.currentClientName = data.clients[0].client_name;
    },
    SET_DETAILS(state, data) {
        state.employee.details = data;
    },
    SET_COMPENSATION(state, data) {
        state.employee.compensation = data;
    },
    ADD_PAYROLL_VOUCHER(state, data) {
        let sum = 0;
        for(let i = 0; i < data.deductions.length; i++){
          sum += parseFloat(data.deductions[i].amount);
        }
        data.totalDeductions = sum
        state.employee.payrollVouchers[data.prism_voucher_id] = data;
    },
    SET_HOURS(state, data) {
        state.employee.hours = data;
    },
    SET_PTO(state, data) {
        state.employee.pto = data;
    },
    SET_HEALTHCARE(state, data) {
        state.employee.healthcare = data;
    },
    SET_CLAIMS(state, data) {
        state.employee.claims = data;
    },
    SET_DENTAL(state, data) {
        state.employee.dental = data;
    },
    SET_VISION(state, data) {
        state.employee.vision = data;
    },
    SET_RETIREMENT(state, data) {
        state.employee.retirement = data;
    },
    SET_LIFE_INSURANCE(state, data) {
        state.employee.lifeInsurance = data;
    },
    SET_ELECTIONS_AND_DOCUMENTS(state, data) {
        state.employee.electionsAndDocuments = data;
    },
    SET_VERIFICATION(state, data) {
        state.employee.verification = data;
    },
    SET_CURRENT_CLIENT(state, client) {
        store.state.currentClientId = client.client_id;
        store.state.currentClientIdReset = store.state.currentClientIdReset + '1';
        store.state.currentClientName = client.client_name;
    },
    RESET_DATA(state) {
        Object.assign(state, getDefaultState())
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}