<template>
    <Loader />
    <component :is="layout">
      <router-view :key="currentClientIdReset"></router-view>
    </component>

</template>

<script>

import { mapGetters } from 'vuex';
import Loader from './components/Loader';
const default_layout = "default"

export default {
  name: 'App',
  components: {
    Loader,
  },
  computed: {
    ...mapGetters({
      currentClientIdReset: 'getCurrentClientIdReset',
    }),
    layout() {
      return (this.$route.meta.layout || default_layout) + '-layout';
    },
  }
};
</script>

<style lang="scss">
#app {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  min-height: 100vh;
  font-size: 16px;
}
</style>
