import httpClient from './httpClient';
import httpMultipartClient from './httpMultipartClient';

const getClients = (userId, corporateUserId) => httpClient.post('/employees/clients', { prism_user_id: userId, corporate_prism_user_id: corporateUserId});
const getDetails = (employeeId, clientId) => httpClient.get('/employees/' + employeeId + '/' + clientId + '/details');
// const END_POINT = '/employees/' + process.env.VUE_APP_EMPLOYEE_ID + '/' + process.env.VUE_APP_CLIENT_ID + '/';
// const getCompensation = () => httpClient.get(END_POINT + 'compensation');
const getCompensation = (employeeId, clientId) => httpClient.get('/employees/' + employeeId + '/' + clientId + '/compensation');
const getCompensationUpdated = (employeeId, clientId) => httpClient.get('/employees/' + employeeId + '/' + clientId + '/compensation_updated');
const getPayrollVoucher = (employeeId, clientId, id) => httpClient.get('/employees/' + employeeId + '/' + clientId + '/payroll_vouchers_updated/' + id);
const getHours = (employeeId, clientId) => httpClient.get('/employees/' + employeeId + '/' + clientId + '/hours');
const getPTO = (employeeId, clientId) => httpClient.get('/employees/' + employeeId + '/' + clientId + '/pto');
const getHealthcare = (employeeId, clientId) => httpClient.get('/employees/' + employeeId + '/' + clientId + '/benefits/health');
const getClaims = (employeeId, clientId) => httpClient.get('/employees/' + employeeId + '/' + clientId + '/claims');
const getDental = (employeeId, clientId) => httpClient.get('/employees/' + employeeId + '/' + clientId + '/benefits/dental');
const getVision = (employeeId, clientId) => httpClient.get('/employees/' + employeeId + '/' + clientId + '/benefits/vision');
const getRetirement = (employeeId, clientId) => httpClient.get('/employees/' + employeeId + '/' + clientId + '/retirement');
const getLifeInsurance = (employeeId, clientId) => httpClient.get('/employees/' + employeeId + '/' + clientId + '/life_insurance');
const getElectionsAndDocuments = (employeeId, clientId) => httpClient.get('/employees/' + employeeId + '/' + clientId + '/elections_and_documents');
const getVerification = (employeeId, clientId) => httpClient.get('/employees/' + employeeId + '/' + clientId + '/verification');
const getW2YearLink = (employeeId, clientId, year) => httpClient.get('/employees/' + employeeId + '/' + clientId + '/w2/' + year);
const emailVerification = (employeeId, clientId, data) => httpMultipartClient.post('/employees/' + employeeId + '/' + clientId + '/email_verification', data);

export {
    getClients,
    getDetails,
    getCompensation,
    getCompensationUpdated,
    getPayrollVoucher,
    getHours,
    getPTO,
    getHealthcare,
    getClaims,
    getDental,
    getVision,
    getRetirement,
    getLifeInsurance,
    getElectionsAndDocuments,
    getVerification,
    getW2YearLink,
    emailVerification,
}