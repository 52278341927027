import httpClient from './httpClient';

const END_POINT = '/content/'

const getWorkersCompensation = () => httpClient.get(END_POINT + 'workers_compensation');
const getWorksiteSafety = () => httpClient.get(END_POINT + 'worksite_safety');

export {
    getWorkersCompensation,
    getWorksiteSafety,
}