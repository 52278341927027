<template>
  <div class="h-full">
    <div class="main-container h-full">
      <MainNav></MainNav>
      <div class="content-container">
        <AppHeader></AppHeader>
        <div class="main-content-container">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainNav from '../components/MainNav';
import AppHeader from '../components/AppHeader';
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      currentClientId: 'getCurrentClientId',
    }),
  },
  components: {
    AppHeader,
    MainNav
  }
}
</script>