import {
    getWorkersCompensation,
    getWorksiteSafety,
 } from "../../api/content.api"

const state = {
    content: {
        workersCompensation: {},
        worksiteSafety: {},
    },
}

const getters = {
    getWorkersCompensation(state) {
        return state.content.workersCompensation;
    },
    getWorksiteSafety(state) {
        return state.content.worksiteSafety;
    },
}

const actions = {
    async fetchWorkersCompensation({ commit }) {
        try {
            const response = await getWorkersCompensation();
            commit('SET_WORKERS_COMPENSATION', response.data);
        } catch (error) {
            // handle the error here
        }
    },
    async fetchWorksiteSafety({ commit }) {
        try {
            const response = await getWorksiteSafety();
            commit('SET_WORKSITE_SAFTETY', response.data);
        } catch (error) {
            // handle the error here
        }
    },
}


const mutations = {
    SET_WORKERS_COMPENSATION(state, data) {
        state.content.workersCompensation = data;
    },
    SET_WORKSITE_SAFTETY(state, data) {
        state.content.worksiteSafety = data;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}