<template>
  <div class="login-container">

    <slot />
  </div>
</template>


<style lang="scss">
.login-container {
  width: 100vw;
  height: 100vh;
}

input {
  @apply border rounded-sm block w-full text-sm p-4;
}

label {
  @apply uppercase block text-left font-medium text-xs mb-2;
  color: #9EA0A5;
}
</style>