<script>
import { store } from '../store/index'
export default {
  name: 'loader',
  data() {
    return {
      store: store
    };
  },
  mounted() {
    // console.log(store.state.loading)
  }
}
</script>

<template>
  <transition name="fade">
    <div v-if="store.state.loading || store.state.contentLoading">
      <div class="loader-container w-full h-full fixed z-1 bg-white">
        <img src="/assets/images/gms-loader.gif" class="absolute inset-2/4" />
      </div>
    </div>
  </transition>
</template>

<style lang="scss">
  .loader-container {
    img {
      margin-top: -52px;
      margin-left: -100px;
      width: 200px;
      height: auto;
    }
  }
</style>