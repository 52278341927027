import axios from 'axios';
import { getAccessToken } from '../msal';
const httpClient = axios.create({
    baseURL: process.env.VUE_APP_BASE_API_URL,
    timeout: 100000,
    headers: {
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': process.env.VUE_APP_APIM_SUBSCRIPTION_KEY,
        'Access-Control-Allow-Origin': '*',
    }
});

httpClient.interceptors.request.use(
    async (config) => {
      if (config?.url?.startsWith('/employees')) {
          // get the access token
          const accessToken = await getAccessToken();
          // add the access token to the authorization header of the request
          if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`;
          }
      }
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );

export default httpClient;