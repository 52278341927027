<script>
import { ref } from 'vue'
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      visible: false,
      activeTab: 0
    }
  },
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot
  },
  computed: {
    ...mapGetters({
      employeeDetails: 'employee/getDetails',
    }),
  },
  methods: {
    show() {
      this.visible = true
    },
  },
  mounted() {
    this.$emitter.on('SHOW_HEALTH_CARD', isOpen => {
      this.visible = isOpen;
    });
  },
  setup() {
    let closeButtonRef = ref(null);

    return {
      closeButtonRef
    }
  },
  unmounted() {
    this.$emitter.all.delete('SHOW_HEALTH_CARD')
  }
}
</script>
<template>
  <TransitionRoot as="template" :show="visible">
    <Dialog as="div" static class="fixed z-30 inset-0 overflow-y-auto" @close="visible = false" :open="visible" :initialFocus="closeButtonRef">
      <div class="flex items-center justify-center min-h-screen px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-100" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-100" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="health-insurance-card inline-block align-bottom bg-white rounded-lg pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full pt-2">
            <div>
              <div class="modal-title flex">
                <DialogTitle as="h3" class="text-xl leading-6 font-medium m-4 dark-gray-text px-4 flex-1 pb-2">
                  Insurance Card
                </DialogTitle>
                <div class="flex-1 flex justify-end relative close-button">
                  <button class="svg-container inline-block" ref="closeButtonRef" @click="visible = false">
                    <img src="/assets/svg/close.svg" />
                  </button>
                </div>
              </div>
              <div class="page-tabs">
                <ul>
                  <li><a @click="activeTab = 0" v-bind:class="{ active: (activeTab == 0) }">Front</a></li>
                  <li><a @click="activeTab = 1" v-bind:class="{ active: (activeTab == 1) }">Back</a></li>
                </ul>
              </div>
              <div class="card p-4" v-if="(activeTab == 0)">
                <div class="card__container card__front border my-6">
                  <div class="card__header flex">
                    <div class="flex-1">
                      <img src="/assets/images/cigna.png"/>
                    </div>
                    <div class="flex-1">
                      <img src="/assets/images/gms_healthcard.png"/>
                    </div>
                  </div>
                  <div class="card__bottom pb-6">
                    <div class='flex'>
                      <div class="flex-1 p-5 pr-2 pb-1">
                        <div class="card__bottom-header font-bold mb-2">
                          Cigna PPO
                        </div>
                        <ul>
                          <li><span>Group #</span>{{ employeeDetails.healthcare_group_number }}</li>
                          <li><span>Cigna Group #</span>{{ employeeDetails.cigna_group_number }}</li>
                          <li><span>Member Name</span>{{ employeeDetails.healthcare_member_name }}</li>
                          <li><span>ID Number</span>{{ employeeDetails.healthcare_id_number }}</li>
                          <li><span>"S"</span></li>
                        </ul>
                      </div>
                      <div class="flex-1 p-5 px-2 pb-1">
                        <div class="card__bottom-header mb-2">
                          <img src="/assets/images/elixirlogo.png"/>
                        </div>
                        <ul>
                          <li><span>RxBIN</span>{{ employeeDetails.healthcare_rx_bin }}</li>
                          <li><span>RxPCN</span>{{ employeeDetails.healthcare_rx_pcn }}</li>
                          <li><span>Rx Group</span>{{ employeeDetails.healthcare_rx_group_number }}</li>
                          <li><span>Website</span>elixirsolutions.com</li>
                        </ul>
                      </div>
                    </div>
                    <p class="font-medium card__lower-text">Benefits not insured Cigna or affiliates. See back of card for additional information.</p>
                  </div>
                </div>
              </div>
              <div class="card p-4" v-if="(activeTab == 1)">
                <div class="card__back pt-6 pb-6">
                  <img src="/assets/images/health_insurance_card_back.png"/>
                </div>
              </div>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

  .health-insurance-card {
    max-width: 540px;
    .page-tabs {
      @apply p-0 h-auto;
      ul {
        @apply flex;
      }
      li {
        @apply p-0 flex-1;
      }
      a {
        @apply block text-center text-sm py-4;
      }
    }
    .card {
      &__container {
        width: 100%;
        max-width: 400px;
        height: 240px;
        @apply mx-auto;
        border: 1px solid #DADADA;
        border-radius: 10px;
      }
      &__header {
        border-bottom: 1px solid #DADADA;
        div:first-child {
          padding: 10px 0 10px 20px;
          img {
            @apply inline-block;
            width: 102px;
            height: auto;
          }
        }
        div:last-child {
          padding-top: 4px;
          margin-left: -22px;
          img {
            @apply inline-block;
            width: 168px;
            height: auto;
          }
        }
      }
      &__bottom {
        img {
          width: 77px;
          height: auto;
        }
        ul {
          font-size: 10px;
          li {
            margin-bottom: 4px;
          }
          span {
            @apply inline-block font-bold;
            width: 86px;
          }
        }
        .flex-1:last-child {
          span {
            width: 64px;
          }
        }
      }
      &__bottom-header {
        font-size: 13px;
      }
      &__lower-text {
        font-size: 8px;
        @apply px-5;
      }
      &__back {
        img {
          width: 400px;
          height: 240px;
          @apply mx-auto;
        }
      }
    }
  }
</style>