import { createWebHistory, createRouter } from "vue-router";
import { store } from './store/index'

const DEFAULT_TITLE = 'GMS Connect';

const routes = [
  {
    path: '/',
    name: 'login',
    props: true,
    component: () =>
      import ('./views/Login.vue'),
    meta: {
      authRequired: false,
      layout: 'login',
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () =>
      import ('./views/Dashboard.vue'),
    meta: {
      authRequired: true,
      title: 'Dashboard'
    }
  },
  {
    path: '/account',
    name: 'account',
    component: () =>
      import ('./views/Account.vue'),
    meta: {
      authRequired: true,
      title: 'Account Settings'
    }
  },
  {
    path: '/my-pay',
    name: 'my-pay',
    component: () =>
      import ('./views/MyPay.vue'),
    meta: {
      authRequired: true,
      title: 'My Pay'
    }
  },
  {
    path: '/my-time',
    name: 'my-time',
    component: () =>
      import ('./views/MyTime.vue'),
    meta: {
      authRequired: true,
      title: 'My Time'
    }
  },
  {
    path: '/health-and-wellness',
    name: 'health-and-wellness',
    component: () =>
      import ('./views/HealthAndWellness.vue'),
    meta: {
      authRequired: true,
      title: 'Health & Wellness'
    }
  },
  {
    path: '/dental-and-vision',
    name: 'dental-and-vision',
    component: () =>
      import ('./views/DentalAndVision.vue'),
    meta: {
      authRequired: true,
      title: 'Dental & Vision'
    }
  },
  {
    path: '/financial-wellness',
    name: 'financial-wellness',
    component: () =>
      import ('./views/FinancialWellness.vue'),
    meta: {
      authRequired: true,
      title: 'Financial Wellness'
    }
  },
  {
    path: '/workers-compensation',
    name: 'workers-compensation',
    component: () =>
      import ('./views/WorkersCompensation.vue'),
    meta: {
      authRequired: true,
      title: "Worker's Compensation"
    }
  },
  {
    path: '/workers-compensation/employee-accident-form',
    name: 'employee-accident-form',
    component: () =>
      import ('./views/EmployeeAccidentForm.vue'),
    meta: {
      authRequired: true,
      title: "Employee Accident Form"
    }
  },
  {
    path: '/worksite-safety',
    name: 'worksite-safety',
    component: () =>
      import ('./views/WorksiteSafety.vue'),
    meta: {
      authRequired: true,
      title: "Worksite Safety"
    }
  },
  {
    path: '/additional-benefits',
    name: 'additional-benefits',
    component: () =>
      import ('./views/AdditionalBenefits.vue'),
    meta: {
      authRequired: true,
      title: "Additional Benefits"
    }
  },
  {
    path: '/documents',
    name: 'documents',
    component: () =>
      import ('./views/Documents.vue'),
    meta: {
      authRequired: true,
      title: "Documents"
    }
  },
  { path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: () =>
      import ('./components/PageNotFound.vue'), 
    meta: {
      authRequired: false,
      layout: 'login',
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if(to.name != 'login' && to.name != 'admin-redirect' && to.name != 'not-found') {
    store.state.lastRoute = to.path
  }

  if (store.state.auth.profileUpdate || store.state.auth.passwordUpdate) {
    //do nothign redirecting to azure
  }
  // //A Logged-in user can't go to login page again
   else if (to.name === 'login' && store.state.auth.isAuthenticated) {
    router.push({
      name: 'dashboard'
    })

    //the route requires authentication
  } else
  if (to.meta.authRequired) {

    if (!store.state.auth.isAuthenticated) {
      router.push({
        name: 'login'
      })
    }
  }
  store.state.contentLoading = true
  document.title = to.meta.title ? DEFAULT_TITLE + " | " + to.meta.title : DEFAULT_TITLE
  return next()
})

export default router