import { PublicClientApplication } from "@azure/msal-browser";

let _msalInstance = null;
const scopes = [process.env.VUE_APP_B2C_SCOPE_READ + 'user_impersonation'];

export const getMsalInstance = () => {
    if (!_msalInstance)
        createMsalInstance();
    return _msalInstance;
}

const createMsalInstance = () => {
    _msalInstance = new PublicClientApplication({
        auth: {
            clientId: process.env.VUE_APP_B2C_CLIENT_ID,
            authority: process.env.VUE_APP_B2C_AUTHORITY,
            knownAuthorities: [process.env.VUE_APP_B2C_KNOWN_AUTHORITIES],
            redirectUri: process.env.VUE_APP_REDIRECT_URI,
        },
        cache: {
            cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO.
            storeAuthStateInCookie: false, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
        },
    });

    return _msalInstance;
}

 export const getAccessToken = async () => {
    // console.log("trying to acquire access token...", getMsalInstance());
    try {
        const currentAccounts = getMsalInstance().getAllAccounts();
        const account = currentAccounts.length > 0 ? currentAccounts[0] : null;
        const accessTokenRequest = {
            scopes: scopes,
            account: account,
            loginHint: account.idTokenClaims.email,
        }
        let tokenResponse = await getMsalInstance().acquireTokenSilent(accessTokenRequest);
        // console.log("attempting to give a response...");
        // console.log(tokenResponse);
    
        return tokenResponse?.accessToken || "";
    } catch (error) {
        console.log("errored out", error);
        return "";
    }
 }